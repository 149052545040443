import {
  SupplierRegistrationProcessFormType,
  SupplierRegistrationProcessInstructionType,
  SupplierRegistrationRadioButtonOptions,
} from '@app/domain/commonSupplierRegistration';
import { COUNTRY_CODES } from '@app/domain/countries';
import { AccountType } from '@app/domain/accountsDetailsHelpers';
import React, { ReactNode } from 'react';
import {
  getSpecialInfo,
  renderSpecialInfo,
  SpecialInfoBadge,
  SpecialInfoComponentType,
} from '@app/components/SpecialInfoComponents';
import styled from '@emotion/styled';

interface SupplierRegistrationRelatedValidatedPayeesInfo {
  knoxIds: string[] | undefined;
  legalEntityIdentifiers: string[] | undefined;
}

export interface SupplierRegistrationConfigurationData {
  futurePaymentsThresholdAmount: number;
  futurePaymentsThresholdCurrency: string;
  useFinicityOpenBanking: boolean;
}

export interface SupplierRegistrationConfigurationDataServerResponse {
  futurePaymentsThresholdAmount: number;
  futurePaymentsThresholdCurrency: string;
  useFinicityOpenBanking: boolean;
}

export interface SupplierRegistrationProcessAddressDetailsServerResponse {
  address: string;
  city: string;
  state: string | null | undefined;
  countryCode: string;
  zipCode: string;
}

export interface SupplierRegistrationProcessServerResponse {
  id: string;
  type: SupplierRegistrationProcessFormType;
  isIndividual: boolean;
  hasAgreed: boolean;
  fullName: string;
  email: string;
  phoneNumber: string;
  companyName: string;
  additionalCompanyName: string | null | undefined;
  address: {
    fullAddress: string | null | undefined;
    addressDetails: SupplierRegistrationProcessAddressDetailsServerResponse | null | undefined;
  };
  legalId: string;
  legalIdType: string;
  countryCode: string;
  companyWebsite: string;
  expectedPaymentsExceedThreshold: SupplierRegistrationRadioButtonOptions | null;
  referringCustomer: string;
  accountDetails: MorteeAccountDetails;
  accountBankCountryCode: string;
  abaRouting: string | null;
  customerIp: string | null;
  registrationNumber: string;
  writeTimestamp: number;
  organizationId: string | null | undefined;
  configurationData: SupplierRegistrationConfigurationDataServerResponse | null | undefined;
  currency: string | null;
  instructionType: SupplierRegistrationProcessInstructionType | null;
  furtherCredit: string | null;
  requestFromWebReferrerOrigins: boolean;
  canUseAch: boolean | null | undefined;
  canUseEft: boolean | null | undefined;
  supplierValidationRelatedValidatedPayeesInfo: SupplierRegistrationRelatedValidatedPayeesInfo | null;
  doesHaveCrowdKnowledgeCandidate: boolean | null | undefined;
  dateOfBirth: number | null | undefined;
  isLinkedToSvManagementRecord: boolean;
  accountType: AccountType | null | undefined;
  registrationId: string | null;
  isIgnored: boolean;
  bankName: string | null | undefined;
  lyonsResponseMatch: LyonsResponseMatch | null | undefined;
  surepayResponseMatch: SurepayResponseMatch | null | undefined;
}

const BANK_OF_AMERICA_SWIFT_PREFIX = 'BOFAUS';
const POALIM_IL_BANK_SWIFT_PREFIX = 'POALIL';
const POALIM_IL_BANK_CODE_PREFIX = '12';
const POALIM_IL_BANK_CODE_OPTIONS = ['12', '012'];
const CHINA_CURRENCY = 'CNY';
const GREAT_BRITAIN_CURRENCY = 'GBP';

/**
 * @deprecated Used for backwards compatibility of bankValidation. Don't add more logic here.
 */
export function calculateBankValidation(
  instructionType: SupplierRegistrationProcessInstructionType | null,
  accountDetails: MorteeAccountDetails | Partial<MorteeAccountDetails>,
  accountBankCountryCode: string,
  currency: string | null,
): SupplierRegistrationProcessInstructionType | null {
  if (instructionType !== SupplierRegistrationProcessInstructionType.bankValidation) {
    return instructionType;
  }

  if (accountDetails.swiftCode?.toUpperCase().startsWith(BANK_OF_AMERICA_SWIFT_PREFIX)) {
    return SupplierRegistrationProcessInstructionType.bankValidationBofa;
  }

  if (accountDetails.swiftCode?.toUpperCase().startsWith(POALIM_IL_BANK_SWIFT_PREFIX)) {
    return SupplierRegistrationProcessInstructionType.bankValidationPoalim;
  }

  // New type of bank validation Poalim - when there was only bank code and the Poalim was a prefix
  if (
    accountBankCountryCode?.toUpperCase() === COUNTRY_CODES.Israel &&
    !accountDetails.branchCode &&
    accountDetails.bankCode?.startsWith(POALIM_IL_BANK_CODE_PREFIX)
  ) {
    return SupplierRegistrationProcessInstructionType.bankValidationPoalim;
  }
  // New type of bank validation Poalim - when branch code was added
  if (
    accountBankCountryCode?.toUpperCase() === COUNTRY_CODES.Israel &&
    accountDetails.branchCode &&
    accountDetails.bankCode &&
    POALIM_IL_BANK_CODE_OPTIONS.includes(accountDetails.bankCode)
  ) {
    return SupplierRegistrationProcessInstructionType.bankValidationPoalim;
  }

  if (accountBankCountryCode?.toUpperCase() === COUNTRY_CODES.Poland) {
    return SupplierRegistrationProcessInstructionType.bankValidationPoland;
  }

  if (accountBankCountryCode?.toUpperCase() === COUNTRY_CODES.Hungary) {
    return SupplierRegistrationProcessInstructionType.bankValidationHungary;
  }

  if (accountBankCountryCode?.toUpperCase() === COUNTRY_CODES.CzechRepublic) {
    return SupplierRegistrationProcessInstructionType.bankValidationCzechRepublic;
  }

  if (accountBankCountryCode?.toUpperCase() === COUNTRY_CODES.India) {
    return SupplierRegistrationProcessInstructionType.bankValidationIndia;
  }

  if (accountBankCountryCode?.toUpperCase() === COUNTRY_CODES.China && currency?.toUpperCase() === CHINA_CURRENCY) {
    return SupplierRegistrationProcessInstructionType.bankValidationChina;
  }

  if (
    accountBankCountryCode?.toUpperCase() === COUNTRY_CODES.UnitedKingdom &&
    currency?.toUpperCase() === GREAT_BRITAIN_CURRENCY
  ) {
    return SupplierRegistrationProcessInstructionType.bankValidationGreatBritain;
  }

  return SupplierRegistrationProcessInstructionType.bankValidation;
}

export function calculateBankValidationOfProcess(
  process: SupplierRegistrationProcessServerResponse,
): SupplierRegistrationProcessInstructionType | null {
  const { accountBankCountryCode, accountDetails, currency, instructionType } = process;
  return calculateBankValidation(instructionType, accountDetails, accountBankCountryCode, currency);
}

export enum LyonsResponseMatch {
  match = 'Match',
  matchWithAlert = 'MatchWithAlert',
  closeMatch = 'CloseMatch',
  noMatch = 'NoMatch',
}

export enum SurepayResponseMatch {
  match = 'Match',
  partialMatch = 'PartialMatch',
  noMatch = 'NoMatch',
  invalid = 'InvalidInformation',
}

export enum ShouldSendInstructionsToSupplier {
  yes = 'Yes',
  no = 'No',
  checkValidatedPayeeAccount = 'Check validated account',
  checkCrowdKnowledgeAccount = 'Check crowd knowledge',
}

export enum WiringFileTypes {
  bofa = 'BOFA',
  bofaAch = 'BOFAACH',
}

export const WiringFileTypesDisplay = {
  [WiringFileTypes.bofa]: { display: 'BOFA' },
  [WiringFileTypes.bofaAch]: { display: 'BOFA ACH' },
};

export interface SupplierRegistrationProcessAddressDetails {
  formattedAddress: string;
  address: string;
  city: string;
  state: string | null;
  countryCode: string;
  zipCode: string;
}

export interface SupplierRegistrationProcess {
  id: string;
  type: SupplierRegistrationProcessFormType;
  isIndividual: boolean;
  hasAgreed: boolean;
  fullName: string;
  email: string;
  phoneNumber: string;
  companyName: string;
  additionalCompanyName: string | null;
  address: {
    fullAddress: string | null;
    addressDetails: SupplierRegistrationProcessAddressDetails | null;
  };
  legalId: string;
  legalIdType: string;
  countryCode: string;
  companyWebsite: string | null;
  expectedPaymentsExceedThreshold: SupplierRegistrationRadioButtonOptions | null;
  referringCustomer: string;
  accountDetails: MorteeAccountDetails;
  accountBankCountryCode: string;
  abaRouting: string | null;
  canUseAch: boolean | null | undefined;
  canUseEft: boolean | null | undefined;
  customerIp: string | null;
  registrationNumber: string;
  writeTimestamp: number;
  organizationId: string | null;
  configurationData: SupplierRegistrationConfigurationData | null;
  currency: string | null;
  instructionType: SupplierRegistrationProcessInstructionType | null;
  furtherCredit: string | null;
  requestFromWebReferrerOrigins: boolean;
  supplierValidationRelatedValidatedPayeesInfo: SupplierRegistrationRelatedValidatedPayeesInfo | null;
  doesHaveCrowdKnowledgeCandidate: boolean | null;
  shouldSendInstructionToSupplier: ShouldSendInstructionsToSupplier;
  dateOfBirth: number | undefined;
  isLinkedToSvManagementRecord: boolean;
  accountType: AccountType | null;
  registrationId: string | null;
  hidden: boolean;
  bankName: string | null;
  lyonsResponseMatch: LyonsResponseMatch | null;
  surepayResponseMatch: SurepayResponseMatch | null;
}

const CrowdKnowledgeCheckBadge = styled(SpecialInfoBadge)`
  background-color: var(--accent-orange-400);
`;

export const ShouldSendInstComponentTypes: Record<ShouldSendInstructionsToSupplier, SpecialInfoComponentType> = {
  [ShouldSendInstructionsToSupplier.yes]: { name: 'Yes', component: (): ReactNode => <SpecialInfoBadge>Yes</SpecialInfoBadge> },
  [ShouldSendInstructionsToSupplier.no]: { name: 'No', component: (): ReactNode => 'No' },
  [ShouldSendInstructionsToSupplier.checkValidatedPayeeAccount]: {
    name: 'Check account - Validated Account Match',
    component: (): ReactNode => (
      <SpecialInfoBadge data-testid='registration-forms-should-send-instructions-validated-payee-check-account'>
        Check account
      </SpecialInfoBadge>
    ),
  },
  [ShouldSendInstructionsToSupplier.checkCrowdKnowledgeAccount]: {
    name: 'Check account - Crowd Knowledge Candidates',
    component: (): ReactNode => (
      <CrowdKnowledgeCheckBadge data-testid='registration-forms-should-send-instructions-ck-check-account'>
        Check account
      </CrowdKnowledgeCheckBadge>
    ),
  },
};

const recordTypeToComponent: Record<SupplierRegistrationProcessFormType, SpecialInfoComponentType> = {
  [SupplierRegistrationProcessFormType.regularSupplierValidation]: { name: 'Regular' },
  [SupplierRegistrationProcessFormType.dynamicSupplierValidation]: { name: 'Dynamic' },
  [SupplierRegistrationProcessFormType.incomingPaymentSecurity]: {
    name: 'IPS',
    component: (): ReactNode => <SpecialInfoBadge>IPS</SpecialInfoBadge>,
  },
  [SupplierRegistrationProcessFormType.externalSupplierValidation]: {
    name: 'External',
    component: (): ReactNode => <SpecialInfoBadge>External</SpecialInfoBadge>,
  },
  [SupplierRegistrationProcessFormType.asyncSupplierValidation]: {
    name: 'Async',
    component: (): ReactNode => <SpecialInfoBadge>Async</SpecialInfoBadge>,
  },
};

export function getRecordType(recordType: SupplierRegistrationProcessFormType): SpecialInfoComponentType | null {
  return getSpecialInfo(recordType, recordTypeToComponent);
}

export function renderRecordType(process: SupplierRegistrationProcess): ReactNode {
  return renderSpecialInfo(getRecordType(process.type));
}
