import { ColorSchemeObject, colorSchemePresets } from '@app/domain/theme';
import React, { FC, ReactNode } from 'react';
import {
  getSpecialInfo,
  renderSpecialInfo,
  SpecialInfoBadge,
  SpecialInfoComponentType,
  TallSpecialInfoBadge,
  wrapSpecialInfo,
} from '@app/components/SpecialInfoComponents';
import styled from '@emotion/styled';
import { ChipProps } from '@app/components/Chip';
import SVG from '@app/components/SVG';
import DownArrowDynamic from '@app/images/arrows/ic-down-arrow-dynamic.svg';
import {
  allSupplierValidationRecordInstructionTypes,
  allSupplierValidationRecordInstructionTypesWithCalculated,
  CommentTypeChipData,
  CommentTypes,
  commentTypesDisplayData,
  getSVMasterFileAutomaticEmailStatusText,
  statusStrings,
  SupplierValidationRecordCalculatedInstructionType,
  SupplierValidationRecordInstructionType,
  SupplierValidationRecordManualInstructionType,
  SupplierValidationRecordStatus,
  SVMasterFileAutomaticEmailStatus,
  SVMasterFileAutomaticEmailStopReason,
} from '@mortee/domain/validationSystem';
import { isDefined, mapValues } from '@app/utils/utils';
import { SupplierRegistrationProcessInstructionType } from '@app/domain/commonSupplierRegistration';

export const instructionStrings: Record<SupplierValidationRecordInstructionType, string> = {
  [SupplierRegistrationProcessInstructionType.poalimBankWireTransfer]: '$10 - Poalim',
  [SupplierRegistrationProcessInstructionType.leumiUSBankWireTransfer]: '$10 - Leumi',
  [SupplierRegistrationProcessInstructionType.incomingPaymentSecurityPoalimBankWireTransfer]: 'IPS - $10 Poalim',
  [SupplierRegistrationProcessInstructionType.incomingPaymentSecurityLeumiUSBankWireTransfer]: 'IPS - $10 Leumi',
  [SupplierRegistrationProcessInstructionType.sepaWireTransfer]: 'SEPA',
  [SupplierRegistrationProcessInstructionType.barclaysGBP]: 'Barclays GBP',
  [SupplierRegistrationProcessInstructionType.barclaysUSD]: 'Barclays USD',
  [SupplierRegistrationProcessInstructionType.barclaysEUR]: 'SEPA Barclays',
  [SupplierRegistrationProcessInstructionType.IPSBarclaysEUR]: 'IPS - SEPA Barclays',
  [SupplierRegistrationProcessInstructionType.achChaseWireTransfer]: 'ACH - Chase',
  [SupplierRegistrationProcessInstructionType.bofaWireTransfer]: 'BOFA Wire Transfer',
  [SupplierRegistrationProcessInstructionType.IPSBOFAWireTransfer]: 'IPS - BOFA Wire Transfer',
  [SupplierRegistrationProcessInstructionType.bofaWirePingPong]: 'BOFA Wire Ping Pong',
  [SupplierRegistrationProcessInstructionType.barclaysPingPong]: 'Barclays Ping Pong',
  [SupplierRegistrationProcessInstructionType.canadaPayoneerPingPong]: 'Canada Payoneer Ping Pong',
  [SupplierRegistrationProcessInstructionType.bankValidationPoalim]: 'Bank Validation - Poalim',
  [SupplierRegistrationProcessInstructionType.bankValidationPoland]: 'Bank Validation - Poland',
  [SupplierRegistrationProcessInstructionType.bankValidationHungary]: 'Bank Validation - Hungary',
  [SupplierRegistrationProcessInstructionType.bankValidationCzechRepublic]: 'Bank Validation - Czech Republic',
  [SupplierRegistrationProcessInstructionType.bankValidationIndia]: 'Bank Validation - India',
  [SupplierRegistrationProcessInstructionType.bankValidationChina]: 'Bank Validation - China',
  [SupplierRegistrationProcessInstructionType.bankValidationGreatBritain]: 'Bank Validation - Great Britain',
  [SupplierRegistrationProcessInstructionType.bankValidationSouthAfrica]: 'Bank Validation - South Africa',
  [SupplierRegistrationProcessInstructionType.bankValidationKorea]: 'Bank Validation - Korea',
  [SupplierRegistrationProcessInstructionType.bankValidationIsrael]: 'Bank Validation - Israel',
  [SupplierRegistrationProcessInstructionType.bankValidationBofa]: 'Bank Validation - BOFA',
  [SupplierRegistrationProcessInstructionType.bankValidation]: 'Bank Validation',
  [SupplierRegistrationProcessInstructionType.bankValidationBlocked]: 'No validation country',
  [SupplierRegistrationProcessInstructionType.japanPayoneer]: 'Japan Payoneer',
  [SupplierRegistrationProcessInstructionType.mexicoFrictionless]: 'Mexico - Nium',
  [SupplierRegistrationProcessInstructionType.mexicoAlternative]: 'Mexico - Payoneer',
  [SupplierRegistrationProcessInstructionType.bankValidationBrazil]: 'Bank Validation - Brazil',
  [SupplierRegistrationProcessInstructionType.bankValidationEurope]: 'Bank Validation - Europe',
  [SupplierRegistrationProcessInstructionType.bankValidationIndonesia]: 'Bank Validation - Indonesia',
  [SupplierRegistrationProcessInstructionType.bankValidationUSOpenBanking]: 'Bank Validation - US Open Banking',
  [SupplierRegistrationProcessInstructionType.bankValidationLyons]: 'Bank Validation - Lyons',
  [SupplierRegistrationProcessInstructionType.chileLF]: 'Chile - Light Friction',
  [SupplierRegistrationProcessInstructionType.argentinaLF]: 'Argentina - Light Friction',
  [SupplierRegistrationProcessInstructionType.vietnamLF]: 'Vietnam - Light Friction',
  [SupplierRegistrationProcessInstructionType.pingPongUSD]: 'Nium - USD',
  [SupplierRegistrationProcessInstructionType.pingPongLocalCurrency]: 'Nium - Local currency',
  [SupplierRegistrationProcessInstructionType.ilLinkedAccount]: 'IL - Linked Account',
  [SupplierValidationRecordManualInstructionType.sepaLinkedAccount]: 'Sepa - Linked Account',
  [SupplierValidationRecordManualInstructionType.bofaLinkedAccount]: 'Bofa - Linked Account',
  [SupplierValidationRecordManualInstructionType.gbGbpLinkedAccount]: 'GB GBP - Linked Account',
  [SupplierValidationRecordManualInstructionType.ewsCandidate]: 'EWS Candidate',
  [SupplierValidationRecordManualInstructionType.ewsMatch]: 'EWS Match',
  [SupplierValidationRecordCalculatedInstructionType.checkAccount]: 'Check account',
  [SupplierValidationRecordCalculatedInstructionType.crowdKnowledge]: 'Check account',
};

//Should probably move the colors a color pallet file when we do one eventually.
const completedColorScheme: ColorSchemeObject = {
  main: '#2e8540',
  fullTextColor: 'var(--white)',
  fullHover: '#2e8540',
  fullActive: '#2e8540',
};
const inProgressColorScheme: ColorSchemeObject = {
  main: '#f2bf72',
  fullTextColor: 'var(--white)',
  fullHover: '#f2bf72',
  fullActive: '#f2bf72',
};
const inApprovalColorScheme: ColorSchemeObject = {
  main: '#A0522D',
  fullTextColor: 'var(--white)',
  fullHover: '#A0522D',
  fullActive: '#A0522D',
};
const completedWithNoEmailsColorScheme: ColorSchemeObject = {
  main: '#f77d02',
  fullTextColor: 'var(--white)',
  fullHover: '#f77d02',
  fullActive: '#f77d02',
};
const canceledColorScheme: ColorSchemeObject = {
  main: '#0071bc',
  fullTextColor: 'var(--white)',
  fullHover: '#0071bc',
  fullActive: '#0071bc',
};
const holdColorScheme: ColorSchemeObject = {
  main: '#bc0dc3',
  fullTextColor: 'var(--white)',
  fullHover: '#bc0dc3',
  fullActive: '#bc0dc3',
};
const deletedColorScheme: ColorSchemeObject = {
  main: '#6b6e70',
  fullTextColor: 'var(--white)',
  fullHover: '#6b6e70',
  fullActive: '#6b6e70',
};
const clarificationColorScheme: ColorSchemeObject = {
  main: '#670f94',
  fullTextColor: 'var(--white)',
  fullHover: '#670f94',
  fullActive: '#670f94',
};
const updateCompletedColorScheme: ColorSchemeObject = {
  main: '#c7ac0c',
  fullTextColor: 'var(--white)',
  fullHover: '#c7ac0c',
  fullActive: '#c7ac0c',
};
const waitingForApprovalColorScheme: ColorSchemeObject = {
  main: '#67832c',
  fullTextColor: 'var(--white)',
  fullHover: '#67832c',
  fullActive: '#67832c',
};
const validationDraftColorScheme: ColorSchemeObject = {
  main: '#3fa1e3',
  fullTextColor: 'var(--white)',
  fullHover: '#3fa1e3',
  fullActive: '#3fa1e3',
};

export const statusColorSchemes: Record<SupplierValidationRecordStatus, ColorSchemeObject> = {
  [SupplierValidationRecordStatus.canceled]: canceledColorScheme,
  [SupplierValidationRecordStatus.hold]: holdColorScheme,
  [SupplierValidationRecordStatus.inProgress]: inProgressColorScheme,
  [SupplierValidationRecordStatus.inApproval]: inApprovalColorScheme,
  [SupplierValidationRecordStatus.completedWithNoEmails]: completedWithNoEmailsColorScheme,
  [SupplierValidationRecordStatus.completed]: completedColorScheme,
  [SupplierValidationRecordStatus.deleted]: deletedColorScheme,
  [SupplierValidationRecordStatus.clarification]: clarificationColorScheme,
  [SupplierValidationRecordStatus.updateCompleted]: updateCompletedColorScheme,
  [SupplierValidationRecordStatus.waitingForApproval]: waitingForApprovalColorScheme,
  [SupplierValidationRecordStatus.validationDraft]: validationDraftColorScheme,
};

const RecordStatusBadge = styled(SpecialInfoBadge)`
  width: 120px;
`;

export const validationRecordStatusSpecialComponents = wrapSpecialInfo<SupplierValidationRecordStatus>(
  validationRecordStatusSpecialComponentsValues(),
);

function validationRecordStatusSpecialComponentsValues(): Record<SupplierValidationRecordStatus, SpecialInfoComponentType> {
  const values = {} as Record<SupplierValidationRecordStatus, SpecialInfoComponentType>;
  Object.values(SupplierValidationRecordStatus).map(
    (status) =>
      (values[status] = {
        name: statusStrings[SupplierValidationRecordStatus[status]],
        component: (): ReactNode => (
          <RecordStatusBadge colorScheme={statusColorSchemes[status]}>{statusStrings[status]}</RecordStatusBadge>
        ),
      }),
  );
  return values;
}

function validationRecordStatusWithArrowSpecialComponentsValues(): Record<
  SupplierValidationRecordStatus,
  SpecialInfoComponentType
> {
  const values = {} as Record<SupplierValidationRecordStatus, SpecialInfoComponentType>;
  Object.values(SupplierValidationRecordStatus).map(
    (status) =>
      (values[status] = {
        name: statusStrings[SupplierValidationRecordStatus[status]],
        component: (): ReactNode => (
          <RecordStatusBadgeWithArrow colorScheme={statusColorSchemes[status]}>
            {statusStrings[status]}
          </RecordStatusBadgeWithArrow>
        ),
      }),
  );

  return values;
}

export const validationRecordStatusWithArrowSpecialComponents = wrapSpecialInfo<SupplierValidationRecordStatus>(
  validationRecordStatusWithArrowSpecialComponentsValues(),
);

const pingPongColorScheme: ColorSchemeObject = {
  main: '#ea43d9',
  fullTextColor: 'var(--white)',
  fullHover: '#ea43d9',
  fullActive: '#ea43d9',
};

const canadaPayoneerPingPongColorScheme: ColorSchemeObject = {
  main: '#50e381',
  fullTextColor: 'var(--white)',
  fullHover: '#50e381',
  fullActive: '#50e381',
};

const bankValidationPoalimColorScheme: ColorSchemeObject = {
  main: '#89bbff',
  fullTextColor: 'var(--white)',
  fullHover: '#89bbff',
  fullActive: '#89bbff',
};

const bankValidationColorScheme: ColorSchemeObject = {
  main: '#5688eb',
  fullTextColor: 'var(--white)',
  fullHover: '#5688eb',
  fullActive: '#5688eb',
};

const bankValidationBlockedColorScheme: ColorSchemeObject = {
  main: '#ed524a',
  fullTextColor: 'var(--white)',
  fullHover: '#ed524a',
  fullActive: '#ed524a',
};

const japanPayoneerColorScheme: ColorSchemeObject = {
  main: '#098225',
  fullTextColor: 'var(--white)',
  fullHover: '#098225',
  fullActive: '#098225',
};

const lightFrictionColorScheme: ColorSchemeObject = {
  main: '#b243ea',
  fullTextColor: 'var(--white)',
  fullHover: '#b243ea',
  fullActive: '#b243ea',
};

const sepaLinkedAccountColorScheme: ColorSchemeObject = {
  main: '#b48648',
  fullTextColor: 'var(--white)',
  fullHover: '#b48648',
  fullActive: '#b48648',
};

const bofaLinkedAccountColorScheme: ColorSchemeObject = {
  main: '#b5a642',
  fullTextColor: 'var(--white)',
  fullHover: '#b5a642',
  fullActive: '#b5a642',
};

const gbGbpLinkedAccountColorScheme: ColorSchemeObject = {
  main: '#e1ad01',
  fullTextColor: 'var(--white)',
  fullHover: '#e1ad01',
  fullActive: '#e1ad01',
};

const ewsCandidateColorScheme: ColorSchemeObject = {
  main: '#f2b2a8',
  fullTextColor: 'var(--white)',
  fullHover: '#f2b2a8',
  fullActive: '#f2b2a8',
};

const ewsMatchColorScheme: ColorSchemeObject = {
  main: '#ffad46',
  fullTextColor: 'var(--white)',
  fullHover: '#ffad46',
  fullActive: '#ffad46',
};

const crowdKnowledgeColorScheme: ColorSchemeObject = {
  main: 'var(--accent-orange-400)',
  fullTextColor: 'var(--white)',
  fullHover: 'var(--accent-orange-400)',
  fullActive: 'var(--accent-orange-400)',
};

const ilLinkedAccountColorScheme: ColorSchemeObject = {
  main: '#7a562d',
  fullTextColor: 'var(--white)',
  fullHover: '#7a562d',
  fullActive: '#7a562d',
};

export const getInstructionColor: Record<SupplierValidationRecordInstructionType, ColorSchemeObject | undefined> = {
  [SupplierRegistrationProcessInstructionType.poalimBankWireTransfer]: undefined,
  [SupplierRegistrationProcessInstructionType.leumiUSBankWireTransfer]: undefined,
  [SupplierRegistrationProcessInstructionType.incomingPaymentSecurityPoalimBankWireTransfer]: undefined,
  [SupplierRegistrationProcessInstructionType.incomingPaymentSecurityLeumiUSBankWireTransfer]: undefined,
  [SupplierRegistrationProcessInstructionType.sepaWireTransfer]: undefined,
  [SupplierRegistrationProcessInstructionType.barclaysGBP]: undefined,
  [SupplierRegistrationProcessInstructionType.barclaysUSD]: undefined,
  [SupplierRegistrationProcessInstructionType.barclaysEUR]: undefined,
  [SupplierRegistrationProcessInstructionType.IPSBarclaysEUR]: undefined,
  [SupplierRegistrationProcessInstructionType.achChaseWireTransfer]: colorSchemePresets.primary300,
  [SupplierRegistrationProcessInstructionType.bofaWireTransfer]: undefined,
  [SupplierRegistrationProcessInstructionType.IPSBOFAWireTransfer]: undefined,
  [SupplierRegistrationProcessInstructionType.bofaWirePingPong]: pingPongColorScheme,
  [SupplierRegistrationProcessInstructionType.barclaysPingPong]: pingPongColorScheme,
  [SupplierRegistrationProcessInstructionType.canadaPayoneerPingPong]: canadaPayoneerPingPongColorScheme,
  [SupplierRegistrationProcessInstructionType.bankValidationPoalim]: bankValidationPoalimColorScheme,
  [SupplierRegistrationProcessInstructionType.bankValidationPoland]: colorSchemePresets.primary300,
  [SupplierRegistrationProcessInstructionType.bankValidationHungary]: colorSchemePresets.primary300,
  [SupplierRegistrationProcessInstructionType.bankValidationCzechRepublic]: colorSchemePresets.primary300,
  [SupplierRegistrationProcessInstructionType.bankValidationIndia]: colorSchemePresets.primary300,
  [SupplierRegistrationProcessInstructionType.bankValidationChina]: colorSchemePresets.primary300,
  [SupplierRegistrationProcessInstructionType.bankValidationGreatBritain]: colorSchemePresets.primary300,
  [SupplierRegistrationProcessInstructionType.bankValidationSouthAfrica]: colorSchemePresets.primary300,
  [SupplierRegistrationProcessInstructionType.bankValidationKorea]: colorSchemePresets.primary300,
  [SupplierRegistrationProcessInstructionType.bankValidationIsrael]: colorSchemePresets.primary300,
  [SupplierRegistrationProcessInstructionType.bankValidationEurope]: colorSchemePresets.primary300,
  [SupplierRegistrationProcessInstructionType.bankValidationIndonesia]: colorSchemePresets.primary300,
  [SupplierRegistrationProcessInstructionType.bankValidationUSOpenBanking]: colorSchemePresets.primary300,
  [SupplierRegistrationProcessInstructionType.bankValidationLyons]: colorSchemePresets.primary300,
  [SupplierRegistrationProcessInstructionType.bankValidationBofa]: bankValidationColorScheme,
  [SupplierRegistrationProcessInstructionType.bankValidation]: bankValidationColorScheme,
  [SupplierRegistrationProcessInstructionType.bankValidationBlocked]: bankValidationBlockedColorScheme,
  [SupplierRegistrationProcessInstructionType.japanPayoneer]: japanPayoneerColorScheme,
  [SupplierRegistrationProcessInstructionType.mexicoFrictionless]: lightFrictionColorScheme,
  [SupplierRegistrationProcessInstructionType.mexicoAlternative]: lightFrictionColorScheme,
  [SupplierRegistrationProcessInstructionType.bankValidationBrazil]: colorSchemePresets.primary300,
  [SupplierRegistrationProcessInstructionType.chileLF]: lightFrictionColorScheme,
  [SupplierRegistrationProcessInstructionType.argentinaLF]: lightFrictionColorScheme,
  [SupplierRegistrationProcessInstructionType.vietnamLF]: lightFrictionColorScheme,
  [SupplierRegistrationProcessInstructionType.pingPongUSD]: pingPongColorScheme,
  [SupplierRegistrationProcessInstructionType.pingPongLocalCurrency]: pingPongColorScheme,
  [SupplierRegistrationProcessInstructionType.ilLinkedAccount]: ilLinkedAccountColorScheme,
  [SupplierValidationRecordManualInstructionType.sepaLinkedAccount]: sepaLinkedAccountColorScheme,
  [SupplierValidationRecordManualInstructionType.bofaLinkedAccount]: bofaLinkedAccountColorScheme,
  [SupplierValidationRecordManualInstructionType.gbGbpLinkedAccount]: gbGbpLinkedAccountColorScheme,
  [SupplierValidationRecordManualInstructionType.ewsCandidate]: ewsCandidateColorScheme,
  [SupplierValidationRecordManualInstructionType.ewsMatch]: ewsMatchColorScheme,
  [SupplierValidationRecordCalculatedInstructionType.checkAccount]: colorSchemePresets.primary300,
  [SupplierValidationRecordCalculatedInstructionType.crowdKnowledge]: crowdKnowledgeColorScheme,
};

function svmInstructionsComponentsValues(): Record<SupplierValidationRecordInstructionType, SpecialInfoComponentType> {
  const values = {} as Record<SupplierValidationRecordInstructionType, SpecialInfoComponentType>;
  allSupplierValidationRecordInstructionTypesWithCalculated.map((instruction) => {
    values[instruction] = { name: instructionStrings[instruction] };
    if (isDefined(getInstructionColor[instruction])) {
      values[instruction].component = (): ReactNode => (
        <SpecialInfoBadge colorScheme={getInstructionColor[instruction]}>{instructionStrings[instruction]}</SpecialInfoBadge>
      );
    }
  });

  return values;
}

export const svmInstructionTypeToComponent = svmInstructionsComponentsValues();

export function getSvmInstructionType(
  instructionType: SupplierValidationRecordInstructionType | null | undefined,
): SpecialInfoComponentType | null {
  return getSpecialInfo(instructionType, svmInstructionTypeToComponent);
}

export function renderSvmInstructionType(instructionType: SupplierValidationRecordInstructionType | null | undefined): ReactNode {
  return renderSpecialInfo(getSvmInstructionType(instructionType));
}

const RecordStatusBadgeWithArrow: FC<ChipProps> = ({ children, ...rest }) => {
  return (
    <SupplierValidationRecordStatusSelectorBadge {...rest}>
      <SupplierValidationRecordStatusSelectorBadgeCenter>{children}</SupplierValidationRecordStatusSelectorBadgeCenter>
      <SupplierValidationRecordStatusSelectorBadgeArrow>
        <SVG accessibilityLabel='' image={DownArrowDynamic} width={18} height={18} />
      </SupplierValidationRecordStatusSelectorBadgeArrow>
    </SupplierValidationRecordStatusSelectorBadge>
  );
};

export const svmInstructionTypeToComponentWithDoubleBorder: Record<
  SupplierValidationRecordInstructionType,
  SpecialInfoComponentType
> = svmInstructionsComponentsValuesWithDoubleBorder();

export function getSvmInstructionTypeWithDoubleBorder(
  instructionType: SupplierValidationRecordInstructionType | null | undefined,
): SpecialInfoComponentType | null {
  return getSpecialInfo(instructionType, svmInstructionTypeToComponentWithDoubleBorder);
}

export function renderSvmInstructionTypeWithDoubleBorder(
  instructionType: SupplierValidationRecordInstructionType | null | undefined,
): ReactNode {
  return renderSpecialInfo(getSvmInstructionTypeWithDoubleBorder(instructionType));
}

function svmInstructionsComponentsValuesWithDoubleBorder(): Record<
  SupplierValidationRecordInstructionType,
  SpecialInfoComponentType
> {
  const values = {} as Record<SupplierValidationRecordInstructionType, SpecialInfoComponentType>;
  allSupplierValidationRecordInstructionTypes.map((instruction) => {
    values[instruction] = { name: instructionStrings[instruction] };
    if (isDefined(getInstructionColor[instruction])) {
      values[instruction].component = (): ReactNode => (
        <DoubleBorderSpecialInfoBadge colorScheme={getInstructionColor[instruction]}>
          {instructionStrings[instruction]}
        </DoubleBorderSpecialInfoBadge>
      );
    }
  });

  return values;
}

const SupplierValidationRecordStatusSelectorBadge = styled(RecordStatusBadge)`
  display: grid;

  grid-template-columns: 1fr auto 1fr;
  grid-template-areas: 'emptySpace content extraContentRight';

  align-items: center;
`;

const SupplierValidationRecordStatusSelectorBadgeCenter = styled.div`
  grid-area: content;
`;

const SupplierValidationRecordStatusSelectorBadgeArrow = styled.div`
  grid-area: extraContentRight;
  fill: var(--chip-text-color);
`;

const DoubleBorderSpecialInfoBadge = styled(SpecialInfoBadge)`
  border-width: 6px;
  border-color: white;
  border-style: double;
`;

export const automaticEmailStatusSpecialComponents = wrapSpecialInfo<SVMasterFileAutomaticEmailStatus>({
  [SVMasterFileAutomaticEmailStatus.notStarted]: {
    name: getSVMasterFileAutomaticEmailStatusText(SVMasterFileAutomaticEmailStatus.notStarted),
  },
  [SVMasterFileAutomaticEmailStatus.inProgress]: {
    name: getSVMasterFileAutomaticEmailStatusText(SVMasterFileAutomaticEmailStatus.inProgress),
  },
  [SVMasterFileAutomaticEmailStatus.stopped]: {
    name: getSVMasterFileAutomaticEmailStatusText(SVMasterFileAutomaticEmailStatus.stopped),
  },
  [SVMasterFileAutomaticEmailStatus.error]: {
    name: getSVMasterFileAutomaticEmailStatusText(SVMasterFileAutomaticEmailStatus.error),
  },
  [SVMasterFileAutomaticEmailStatus.ended]: {
    name: getSVMasterFileAutomaticEmailStatusText(SVMasterFileAutomaticEmailStatus.ended),
  },
});

export const automaticEmailStopReasonSpecialComponents = wrapSpecialInfo<SVMasterFileAutomaticEmailStopReason>({
  [SVMasterFileAutomaticEmailStopReason.originalEmailNotFound]: {
    name: 'Original email not found',
  },
  [SVMasterFileAutomaticEmailStopReason.automaticEmailNotFound]: {
    name: 'Automatic email not found',
  },
  [SVMasterFileAutomaticEmailStopReason.sendingEmailFailed]: {
    name: 'Sending email failed',
  },
  [SVMasterFileAutomaticEmailStopReason.emailTemplateProcessingFailed]: {
    name: 'Template processing failed',
  },
  [SVMasterFileAutomaticEmailStopReason.invalidEmailParameters]: {
    name: 'Invalid parameters',
  },
  [SVMasterFileAutomaticEmailStopReason.invalidEmailMessageEmailId]: {
    name: 'Invalid email message email id',
  },
  [SVMasterFileAutomaticEmailStopReason.generalError]: {
    name: 'Error',
  },
  [SVMasterFileAutomaticEmailStopReason.registered]: {
    name: 'Supplier register',
  },
  [SVMasterFileAutomaticEmailStopReason.customerInvolvement]: {
    name: 'Customer involvement',
  },
  [SVMasterFileAutomaticEmailStopReason.newPhase]: {
    name: 'New Phase',
  },
  [SVMasterFileAutomaticEmailStopReason.emailInterruption]: {
    name: 'Email interrupted',
  },
  [SVMasterFileAutomaticEmailStopReason.noResponse]: {
    name: 'No response',
  },
  [SVMasterFileAutomaticEmailStopReason.stopAutomaticEmailProcessRequest]: {
    name: 'Stop email requested',
  },
});

export const commentTypesSpecialComponents = wrapSpecialInfo<CommentTypes>(
  mapValues(
    commentTypesDisplayData,
    (displayData: CommentTypeChipData): SpecialInfoComponentType => ({
      name: displayData.text,
      component: () => (
        <TallSpecialInfoBadge appearance='outline-squared' colorScheme={displayData.colorScheme}>
          {displayData.text}
        </TallSpecialInfoBadge>
      ),
    }),
  ),
);

function instructionsComponentsValues(): Record<SupplierRegistrationProcessInstructionType, SpecialInfoComponentType> {
  const values = {} as Record<SupplierRegistrationProcessInstructionType, SpecialInfoComponentType>;
  Object.values(SupplierRegistrationProcessInstructionType).map((instruction) => {
    values[instruction] = { name: instructionStrings[instruction] };
    if (isDefined(getInstructionColor[instruction])) {
      values[instruction].component = (): ReactNode => (
        <SpecialInfoBadge colorScheme={getInstructionColor[instruction]}>{instructionStrings[instruction]}</SpecialInfoBadge>
      );
    }
  });

  return values;
}

export const instructionTypeToComponent: Record<
  SupplierRegistrationProcessInstructionType,
  SpecialInfoComponentType
> = instructionsComponentsValues();

export function getInstructionType(
  instructionType: SupplierRegistrationProcessInstructionType | null | undefined,
): SpecialInfoComponentType | null {
  return getSpecialInfo(instructionType, instructionTypeToComponent);
}

export function renderInstructionType(instructionType: SupplierRegistrationProcessInstructionType | null | undefined): ReactNode {
  return renderSpecialInfo(getInstructionType(instructionType));
}
